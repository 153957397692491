<template>
  <div>
    <b-collapse
      v-for="(days, month) in daysGroupedByMonth"
      :key="`days_${month}`"
      :open="isOpen == index"
      animation="slide"
      class="bg-white rounded-xl px-3 pt-2 pb-3 flex flex-col gap-2 mb-2"
      :aria-id="month"
      @open="isOpen = index"
    >
      <template #trigger="props">
        <div
          class="inline-flex items-center justify-between w-full gap-3"
          role="button"
          :aria-controls="month"
          :aria-expanded="props.open"
        >
          <div class="font-semibold">
            <div class="text-lg capitalize">
              {{ new Date(month).toLocaleDateString('pt-PT', { month: 'long' }) }}
            </div>
          </div>

          <div class="flex-1">
            <div v-if="!props.open && showDaysOnCollapsed" class="flex gap-1 flex-wrap">
              <div
                v-for="(day, i) in days"
                :key="`day_${day}_${i}`"
                class="rounded-full p-1 bg-gray-200 bg-opacity-30 text-xs w-6 text-center font-medium text-gray-500 flex-shrink-0 flex-wrap"
              >
                {{ parseDayNumber(day.date) }}
              </div>
            </div>
          </div>

          <div class="flex gap-1 items-center">
            <div class="text-sm text-gray-500">
              {{
                days.reduce((previous, current) => {
                  return current.type == 'allday' || current.type == 'creditday' || current.type == 'birthday'
                    ? (previous += 1)
                    : (previous += 0.5)
                }, 0)
              }}
            </div>
            <div><fw-icon-calendar class="w-4 h-4 text-gray-400" /></div>
          </div>

          <div class="w-5 h-5">
            <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
            <fw-icon-chevron-down v-else class="w-5 h-5 " />
          </div>
        </div>
      </template>

      <div class="w-full flex flex-col gap-2 rounded-lg">
        <slot name="days" :days="days"></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    daysGroupedByMonth: {
      type: Object,
      required: true
    },
    showDaysOnCollapsed: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: this.collapsed ? 0 : null,
      index: null
    }
  },

  methods: {
    // Parse day number from date string
    parseDayNumber(date) {
      return new Date(date).getDate()
    }
  }
}
</script>
