<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <template #trigger="{ active }">
      <fw-button
        size="sm"
        class="flex gap-2"
        :class="{
          'opacity-100': active
        }"
      >
        <div class="flex items-center gap-1">
          <fw-icon-filter class="fill-current text-gray-500 h-5 w-5"></fw-icon-filter>
          <span v-if="appliedFilters.length"
            ><fw-tag type="xlight" size="xs"> {{ selectedFilters.length }}</fw-tag></span
          >
        </div>
      </fw-button>
    </template>

    <div class="mb-3 z-10">
      <div v-for="(filter, key) in filters" :key="`filter_cat_${key}`">
        <fw-label v-if="filter.label && filter.label.length > 0" class="ml-4 pt-3 pb-2">{{ filter.label }}</fw-label>
        <div class="flex flex-wrap gap-2 mx-4">
          <div
            v-for="(option, o) in filter.options"
            :key="`filter_cat_${key}_opt_${o}`"
            class="filter-tag"
            :class="{ active: checkIsFilterActive(filter.key, option.key) }"
            @click="selectFilter(filter.key + ':' + option.key)"
          >
            {{ option.label }}
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    appliedFilters: {
      type: Array,
      default: () => []
    },

    filters: {
      type: Array,
      default: () => []
    },

    multifilter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedFilters: []
    }
  },

  watch: {
    appliedFilters(newValue) {
      this.selectedFilters = JSON.parse(JSON.stringify(newValue))
    }
  },

  mounted() {
    console.log(this.appliedFilters)
    this.selectedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
  },

  methods: {
    selectFilter(filter) {
      var parts = filter.split(':')
      if (parts.length > 1 && (parts[1] == 'reset' || !this.multifilter)) {
        //reset
        this.selectedFilters = this.selectedFilters.filter(function(e) {
          return !e.startsWith(parts[0] + ':')
        })
        if (!this.multifilter) {
          this.selectedFilters.push(filter)
        }
      } else {
        if (this.selectedFilters.includes(filter)) {
          this.selectedFilters = this.selectedFilters.filter(function(e) {
            return e !== filter
          })
        } else {
          this.selectedFilters.push(filter)
        }
      }

      this.$emit('filter-changed', this.selectedFilters)
    },

    checkIsFilterActive(filterGroupKey, filterValueKey) {
      if (filterValueKey === 'reset') {
        for (var i = 0; i < this.selectedFilters.length; i++) {
          if (this.selectedFilters[i].startsWith(filterGroupKey + ':')) {
            return false
          }
        }
        return true
      }

      // Check any valid option
      if (this.selectedFilters.includes(filterGroupKey + ':' + filterValueKey)) {
        return true
      }

      return false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "applyFilters": "Aplicar filtros",
    "filter": "Filtrar"
  },
  "en": {
    "applyFilters": "Apply filters",
    "filter": "Filter"
  }
}
</i18n>

<style lang="postcss">
.filter-tag {
  @apply px-2.5 select-none py-1 flex items-center cursor-pointer bg-gray-100 text-gray-600 rounded-lg text-sm;
}
.filter-tag.active {
  @apply bg-primary text-white font-medium;
}
</style>
