<template>
  <fw-panel :title="title" custom-class="flex flex-col gap-5">
    <div class="flex gap-5 px-4 pt-2 pb-4 rounded-xl h-full bg-gray-50">
      <div class="pt-3 w-96 flex justify-center">
        <b-datepicker
          inline
          multiple
          locale="pt-PT"
          icon="calendar-alt"
          trap-focus
          :unselectable-dates="unselectableDates.map(d => new Date(`${d}T00:00:00`))"
          :unselectable-days-of-week="unselectableDaysOfWeek"
          :nearby-month-days="false"
          :events="events"
          :first-day-of-week="1"
          :min-date="new Date(`${year}-01-01`)"
          :max-date="new Date(`${year}-12-31`)"
          :disabled="allAvailableDaysSelected"
          @input="updateDatesToAdd"
        >
        </b-datepicker>
      </div>
      <div class="flex-1 px-1 pt-1.5 relative">
        <div class="flex gap-5 items-center justify-between">
          <div>
            <fw-label>Dias a adicionar</fw-label>
          </div>
          <div v-if="datesToAdd.length">
            <fw-tag size="xs" :type="allAvailableDaysSelected ? 'light' : 'light-primary'">
              {{ toAddCount }} / {{ availableDaysToSelect }}
            </fw-tag>
          </div>
        </div>
        <div v-if="datesToAdd.length" class="overflow-y-auto max-h-96 mt-2 h-80">
          <div
            v-for="(dayToAdd, index) in datesToAddWithTypes"
            :key="`day_${index}`"
            class="flex items-center gap-2 px-2 py-1 bg-gray-100 rounded-xl mb-1"
          >
            <div class="flex gap-1 items-center font-semibold text-primary w-32">
              <fw-icon-add-circle class="h-5 w-5 flex-shrink-0" />
              <span>{{ dayToAdd.date }}</span>
            </div>
            <div>
              <b-dropdown
                v-if="canAddPartialDay || !fullDayTypes.includes(dayToAdd.type)"
                v-model="dayToAdd.type"
                aria-role="list"
                position="is-bottom-left"
              >
                <fw-button-dropdown
                  slot="trigger"
                  aria-role="listitem"
                  type="xlight"
                  :label="$t(`types.${dayToAdd.type}`)"
                  class="font-medium text-gray-500"
                >
                </fw-button-dropdown>
                <b-dropdown-item
                  label="Todo o dia"
                  aria-role="listitem"
                  :value="isCreditdays ? 'creditday' : 'allday'"
                  :disabled="remainingDays == 0"
                >
                  Todo o dia
                </b-dropdown-item>
                <b-dropdown-item
                  label="Manhã"
                  aria-role="listitem"
                  :value="isCreditdays ? 'creditday-morning' : 'morning'"
                >
                  Manhã
                </b-dropdown-item>
                <b-dropdown-item
                  label="Tarde"
                  aria-role="listitem"
                  :value="isCreditdays ? 'creditday-afternoon' : 'afternoon'"
                >
                  Tarde
                </b-dropdown-item>
              </b-dropdown>
              <div v-else>
                <fw-tag type="xlight">{{ $t(`types.${dayToAdd.type}`) }}</fw-tag>
              </div>
            </div>
            <div class="flex-1"></div>
            <div>
              <fw-button type="link-muted" @click.native="deleteDay(dayToAdd)">
                <fw-icon-close class="h-5 w-5" />
              </fw-button>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-xs text-gray-500 mt-2 flex justify-center items-center bg-gray-100 rounded-xl max-h-80 h-80"
        >
          Escolha um ou mais dias no calendário para adicionar.
        </div>
      </div>
    </div>

    <div class="flex items-center justify-end gap-5">
      <fw-button type="link-muted" @click.native="$emit('close')">
        Cancelar
      </fw-button>
      <fw-button :disabled="!datesToAdd.length || loading" type="primary" :loading="loading" @click.native="addDays">
        Adicionar <span class="font-normal text-xs">({{ datesToAdd.length }})</span>
      </fw-button>
    </div>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer
        :value="{
          datesToAdd,
          unselectableDates,
          unselectableDaysOfWeek,
          datesToAddWithTypes,
          ...daysCount,
          toAddCount,
          allAvailableDaysSelected,
          remainingDays,
          canAddPartialDay,
          events
        }"
      ></json-viewer>
    </fw-panel-info>
  </fw-panel>
</template>

<script>
import difference from 'lodash/difference'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { FULL_DAY_TYPES } from '@/utils'

export default {
  props: {
    isCreditdays: {
      type: Boolean,
      default: () => []
    },
    unselectableDates: {
      type: Array,
      default: () => []
    },
    excludedWeekdays: {
      type: Array,
      default: () => []
    },
    events: {
      type: Array,
      default: () => []
    },
    canAddPartialDay: {
      type: Boolean,
      default: false
    },
    year: {
      type: Number
    },
    daysCount: {
      type: Object,
      default: () => {
        return {
          total: 22,
          selected: 0,
          remaining: 22
        }
      }
    }
  },

  data() {
    return {
      fullDayTypes: Object.freeze(FULL_DAY_TYPES),
      loading: false,
      datesToAdd: [],
      datesToAddWithTypes: []
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    lang() {
      return this.$store.state.language
    },

    title() {
      if (this.isCreditdays) {
        return 'Adicionar dias de crédito'
      }

      return 'Adicionar dias de férias'
    },

    unselectableDaysOfWeek() {
      return this.excludedWeekdays.concat([0, 6])
    },

    allAvailableDaysSelected() {
      return this.toAddCount + this.daysCount.selected >= this.daysCount.total
    },

    availableDaysToSelect() {
      return this.daysCount.total - this.daysCount.selected
    },

    toAddCount() {
      return this.datesToAddWithTypes.reduce((previous, current) => {
        return current.type == 'allday' || current.type == 'creditday' ? (previous += 1) : (previous += 0.5)
      }, 0)
    },

    remainingDays() {
      return this.daysCount.total - (this.toAddCount + this.daysCount.selected)
    }
  },

  methods: {
    formatDate(value) {
      return Dates.buildCore(value)
        .tz('Europe/Lisbon')
        .format('YYYY-MM-DD')
    },
    deleteDay(dayToAdd) {
      console.log('deleteDay :>> ', dayToAdd)

      let index = this.datesToAdd.findIndex(el => this.formatDate(el) == this.formatDate(dayToAdd.date))
      if (index > -1) {
        this.datesToAdd.splice(index, 1)
      }

      index = this.datesToAddWithTypes.indexOf(dayToAdd)
      if (index > -1) {
        this.datesToAddWithTypes.splice(index, 1)
      }
    },

    updateDatesToAdd(dates) {
      // console.log('from datepicker', dates)
      // console.log('current in list', this.datesToAdd)
      const newDate = difference(dates, this.datesToAdd)
      // console.log('newDate', newDate)

      this.datesToAdd = dates

      // console.log('new in list', this.datesToAdd)
      if (!newDate.length) {
        this.datesToAddWithTypes = this.datesToAddWithTypes.filter(el =>
          dates.map(el => this.formatDate(el)).includes(el.date)
        )
      } else {
        const remainingDays = this.daysCount.total - (this.toAddCount + this.daysCount.selected)
        let type = remainingDays == 0.5 ? 'morning' : 'allday'
        if (this.isCreditdays) {
          type = remainingDays == 0.5 ? 'creditday-morning' : 'creditday'
          this.datesToAddWithTypes.unshift({
            date: this.formatDate(newDate[0]),
            type: type
          })

          return
        }

        this.datesToAddWithTypes.unshift({
          date: this.formatDate(newDate[0]),
          type: type
        })
      }
      console.log('this.datesToAddWithTypes', this.datesToAddWithTypes)
    },

    addDays() {
      this.loading = true
      this.$emit('add', this.datesToAddWithTypes)
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito Manhã",
        "creditday-afternoon": "Dia de crédito Tarde",
        "creditday-halfday": "Dia de crédito parcial"
      }
    },
    "en": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito manhã",
        "creditday-afternoon": "Dia de crédito tarde",
        "creditday-halfday": "Dia de crédito parcial"
      }
    }
  }
</i18n>
