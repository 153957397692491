<template>
  <div>
    <div class="flex flex-col gap-3">
      <button
        class="bg-gray-100 rounded-lg hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-medium"
        :disabled="!canCreateVersion.vacations"
        :class="{
          'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !canCreateVersion.vacations
        }"
        @click="createVersion('vacations')"
      >
        <div class="flex-1 text-left">
          {{ motives.vacations[lang] }}
        </div>
        <fw-tag v-if="canCreateVersion.vacations" type="primary">Criar</fw-tag>
      </button>

      <button
        class="bg-gray-100 rounded-lg hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-medium"
        :disabled="!canCreateVersion.credit_days"
        :class="{
          'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !canCreateVersion.credit_days
        }"
        @click="createVersion('credit_days')"
      >
        <div class="flex-1 text-left ">
          <p>{{ motives.credit_days[lang] }}</p>
          <fw-panel-info v-if="!canCreateVersion.credit_days" clean>Não tem dias de crédito anual.</fw-panel-info>
        </div>
        <fw-tag v-if="canCreateVersion.credit_days" type="primary">Criar</fw-tag>
      </button>

      <button
        class="bg-gray-100 rounded-lg hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-medium"
        :disabled="!canCreateVersion.accumulate_days"
        :class="{
          'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !canCreateVersion.accumulate_days
        }"
        @click="createVersion('accumulate_days')"
      >
        <div class="flex-1 text-left">
          {{ motives.accumulate_days[lang] }}
          <fw-panel-info v-if="!canCreateVersion.accumulate_days" clean
            >A cumulação de dias para o ano {{ mapYear }} ainda não está disponível.</fw-panel-info
          >
        </div>
        <fw-tag v-if="canCreateVersion.accumulate_days" type="primary">Criar</fw-tag>
      </button>
    </div>

    <div class="flex items-center justify-end gap-5 mt-5">
      <fw-button type="link-muted" @click.native="$emit('close')">
        Cancelar
      </fw-button>
    </div>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ mapKey, canCreateVersion }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { TO_CHOOSE_MAP_USER_VERSION_REASONS } from '@/utils/index'

export default {
  props: {
    canCreateVersion: {
      type: Object,
      default: () => {
        return {
          vacations: true,
          credit_days: false,
          accumulate_days: false
        }
      }
    },
    mapYear: {
      type: Number,
      required: true
    },
    mapKey: {
      type: String,
      required: true
    },
    userKey: {
      type: String
    },
    manager: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      motives: TO_CHOOSE_MAP_USER_VERSION_REASONS
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    lang() {
      return this.$store.state.language
    }
  },

  methods: {
    async createVersion(motive) {
      await utils.tryAndCatch(this, async () => {
        if (this.manager) {
          const response = await this.api.createManageUserMapVersion(this.mapKey, this.userKey, motive)
          this.$emit('created')
          this.$router.push({
            name: 'manage-map-user',
            params: {
              year: this.mapYear,
              key: this.mapKey,
              userKey: this.userKey,
              mapUserKey: response.user_map.key
            }
          })
        } else {
          const response = await this.api.createUserMapVersion(this.mapKey, motive)
          this.$emit('created')
          this.$router.push({ name: 'map', params: { key: response.user_map.key } })
        }
      })
    }
  }
}
</script>
